import React, { useEffect } from 'react';
import styles from './App.module.css';
import SelectRoom from "./features/select-room/SelectRoom";
import {
    activeSessionIdSelector,
    pingPongIntervalSelector,
    indiaTimeSelector,
    azTimeSelector,
    updateClocks,
    connectingToWsSelector,
    updateConnectionStatus,
    connectionErrorSelector,
    updateConnectionError,
    updateActiveSessionId
} from "./AppSlice";
import { useDispatch, useSelector } from "react-redux";
import PointingSession from "./features/pointing-session/PointingSession";
import WebSocketClient from "./app/wsClient";
import { Client } from "./types";

function App() {
    let activeSessionId = useSelector(activeSessionIdSelector);
    let pingPongInterval = useSelector(pingPongIntervalSelector);
    let indiaTime = useSelector(indiaTimeSelector);
    let azTime = useSelector(azTimeSelector);
    let dispatch = useDispatch();
    let connectingToWs = useSelector(connectingToWsSelector);
    let connectionError = useSelector(connectionErrorSelector);

    let cachedClient: Client = getCachedClient();
    if (!cachedClient) {
        cacheClient({
            "id": null,
            "name": null,
            "currentScore": null,
            "isParticipant": false,
            "isModerator": false
        });
    }

    useEffect(() => {
        const initialize = async () => {
            const ws = new WebSocketClient();
            
            await ws.init();

            ws.send("getClientId", {});

            setInterval(() => {
                ws.send("pingPong", {"ping": "PING"});
            }, pingPongInterval);

            let existingSessionId = getCachedSessionId();
            if (existingSessionId) {
                dispatch(updateActiveSessionId(existingSessionId));
                ws.send("enterSession", {
                    sessionId: existingSessionId,
                    client: getCachedClient()
                });
            }
        }


        initialize().then(() => {
            dispatch(updateConnectionStatus(false));
            console.log("App Initialized with WS Connection")
        }).catch(err => {
            console.log(err);
            dispatch(updateConnectionError(err));
        });


        setInterval(function(){
            const indiaTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', 'minute': '2-digit', timeZone: 'Asia/Kolkata' });
            const azTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', 'minute': '2-digit', timeZone: 'America/Phoenix' });
            dispatch(updateClocks({ indiaTime, azTime }));
        }, 1000);

    }, [dispatch, pingPongInterval]);

    return (
        <div className={styles.App}>
            <div>
                <h1 className={styles.pointingPokerHeading}>Story Pointing</h1>
                <img className={styles.cardsLogo} src="img/cards.png" alt="Playing Cards Icon" />
                <div className={styles.clockTimersContainer}>
                    <div className={styles.usClock}>
                        <div className={styles.flagIcon}><img alt="America Flag" src="img/united-states-of-america.png" /></div>
                        <div className={styles.timeContainer}>{ azTime }</div>
                        <div style={{clear: "both"}}></div>
                    </div>
                    <div className={styles.indiaClock}>
                        <div className={styles.flagIcon}><img alt="India Flag" src="img/india.png" /></div>
                        <div className={styles.timeContainer}>{ indiaTime }</div>
                        <div style={{clear: "both"}}></div>
                    </div>
                </div>
                <div style={{clear: "both"}}></div>
            </div>

            { connectingToWs && !connectionError &&
                <div className={styles.connectingState}>
                    <img className={styles.spinnerCardGif} src="img/poker.gif" alt="Connecting, Please Wait..." />
                    <div>Shuffling the Cards.....</div>
                    <div style={{color: "#888", fontStyle: "italic", marginTop: "7px"}}>(Connecting to the Web Socket Server)</div>
                </div>
            }

            { connectionError &&
                <div className={styles.connError}>
                    { connectionError }
                </div>

            }

            { !connectingToWs &&
                <>
                    {!activeSessionId ?
                        <SelectRoom /> :
                        <PointingSession />
                    }
                </>
            }
        </div>
    );
}

export const cacheSessionId = (sessionId: string) => {
    window.sessionStorage.setItem("pointingPokerSessionId", sessionId);
}

export const getCachedSessionId = () => {
    return window.sessionStorage.getItem("pointingPokerSessionId");
}

export const removeCachedSessionId = () => {
    window.sessionStorage.removeItem("pointingPokerSessionId");
}

export const cacheClient = (client: Client) => {
    window.sessionStorage.setItem("pointingPokerClient", JSON.stringify(client));
}

export const getCachedClient = () => {
    let cachedClientJsonString = window.sessionStorage.getItem("pointingPokerClient");
    if (cachedClientJsonString) {
        return JSON.parse(cachedClientJsonString);
    }
}

export const updateCachedClient = (updates: Client) => {
    let cachedClient = getCachedClient();
    if (cachedClient) {
        let updatedCachedClient = Object.assign({}, cachedClient, updates);
        window.sessionStorage.setItem("pointingPokerClient", JSON.stringify(updatedCachedClient));
    }
}

export default App;
